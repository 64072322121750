<template>
  <div class="main-container">
    <v-toolbar flat tile class="box" style="background-color: transparent">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0"> รายชื่อพนักงาน </v-toolbar-title>
    </v-toolbar>

    <div class="mx-4 box box-2" style="min-height: 100%">
      <v-row
        dense
        class="box box-2"
        style="height: 100%; display: flex; flex-direction: row"
      >
        <v-col style="height: 100%">
          <v-card
            style="height: 100%;overflow: auto"
            class="pa-2 rounded-lg elevation-2"
            outlined
            tile
          >
            <v-data-table
              :loading="loading"
              :items="users"
              :headers="userHeaders"
              :header-props="{ sortIcon: null }"
            >
              <template v-slot:item.user="{ item }">
                {{ item.user.first_name }} {{ item.user.last_name }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn @click="goDetail(item.user.id)" icon>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DateTime from "luxon/src/datetime";

export default {
  name: "Salary",
  data: () => ({
    items: [],
    userHeaders: [
      {
        text: "ชื่อ",
        value: "user",
      },
      {
        text: "ตำแหน่ง",
        value: "user.position.name",
        align: "center",
      },
      {
        text: "เบอร์โทร",
        value: "user.tel",
        align: "center",
      },
      // {
      //   text: "ค่าคอมมิชชั่นค้างจ่ายย้อนหลัง 2 เดือน",
      //   value: "commission",
      //   align: "center",
      // },
      {
        text: "",
        value: "action",
        width: "100",
      },
    ],
    users: [],
    loading: false,
  }),
  created() {
    this.getListCommission();
  },
  methods: {
    getListCommission() {
      let today = DateTime.now().toFormat("yyyy/LL/dd");
      let startDate = DateTime.now().minus({ month: 2 }).toFormat("yyyy/LL/dd");
      this.loading = true
      axios
        .get("/api/tracks/commission/", {
          params: { start_date: startDate, end_date: today },
        })
        .then((res) => {
          this.users = res.data.data.filter((u) => u.user.username !== "admin");
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        })
        .finally(()=>{
          this.loading = false
        })
    },
    goDetail(id) {
      this.$router.push({ path: `/salary/${id}` });
    },
  },
};
</script>

<style scoped>
</style>
